.emergency-plan {
  padding: 3rem 0;

  .container {
    align-items: flex-start;
    justify-content: space-evenly;
      > div:first-child {
        margin-right: 2rem;
      }

    @media (max-width: 767px) {
      flex-direction: column;

    }
  }

  p {
    a {
      white-space: nowrap;
    }
  }

  .label {
    display: inline-block;
    padding: 0.5rem;
    border-radius: 4px;
    background: #dd4b39;
    color: white;
    text-transform: uppercase;
  }

  h3 {
    margin-top: 3rem;
  }

  ol {
    list-style: none;
    padding: 0;
  }

  .list-style-circle {
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 1rem;
    background: #dd4b39;
    color: white;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    position: relative;
    font-weight: bold;
  }

  li {
    display: flex;
    align-items: center;
  }

  li:not(:first-child) {
    .list-style-circle:before {
      content: '';
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: #dd4b39;
      position: absolute;
      top: -1rem;
    }
  }

  li:not(:last-child) {
    .list-style-circle:after {
      content: '';
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: #dd4b39;
      position: absolute;
      bottom: -1rem;
    }
  }

  &__info {
    background: whitesmoke;
    margin: 0;
    padding: 2rem;
    line-height: 1.5em;
  }
}

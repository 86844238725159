.page-footer {
  border-top: 1px solid #323232;
  padding: 2rem 0;

  .container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  nav {
    margin-bottom: 1rem;
    a {
      color: #323232;
      text-decoration: none;
      margin: 0 0.5rem;
      &:hover {
        color: #a3a3a4;
      }

      &:active {
        color: #696969;
      }
    }
  }
}

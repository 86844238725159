html {
  font-size: 100%;
  font-family: 'Open Sans', sans-serif;
  color: #323232;
}

@media (max-width: 767px) {
  html {
    font-size: 75%;
  }
}

h1 {
  margin: 0px 0px 1em;
  font-size: 2.7em;
  font-weight: 300;
  line-height: 1.2em;
}

h2 {
  margin: 0px 0px 0.3em;
  font-size: 1.8em;
  font-weight: 300;
  line-height: 1.2em;
}

h3 {
  margin: 0px 0px 0.3em;
  font-size: 1.3em;
  font-weight: 700;
  line-height: 1.5em;
}

p {
  margin-top: 0px;
  margin-bottom: 2rem;
  line-height: 1.5em;
}

ul,
ol {
  margin-top: 0px;
  margin-bottom: 2rem;
  line-height: 1.5em;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
}

.container--text {
  margin-top: 8rem;
  flex-direction: column;
}

@media (min-width: 735px) {
  .container {
    padding: 0 2rem;
  }
}

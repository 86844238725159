.button {
  color: white;
  display: inline-block;
  font-size: 1.25rem;
  z-index: 1;
  text-decoration: none;
  line-height: 1em;
  border-radius: 4px;
  white-space: nowrap;
  padding: 1rem 2rem;
  background: #dd4b39;

  &:hover {
    background: lighten(#dd4b39, 3%);
  }

  &:active {
    background: darken(#dd4b39, 2%);
  }
}

.page-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;

  .container {
    align-items: center;
  }
  nav {
    margin-left: auto;
    margin-top: 1.5rem;
    a {
      color: white;
      text-decoration: none;
      &:hover {
        color: #dadada;
      }

      &:active {
        color: #a3a3a4;
      }

      &.dark-nav {
        color: #323232;
      }

      &.dark-nav:hover {
        color: #a3a3a4;
      }

      &.dark-nav:active {
        color: #696969;
      }
    }
  }
}

.logo {
  position: fixed;
  top: 0;
  padding: 1rem 1rem 0.5rem 1rem;
  display: inline-block;
  border: 1px solid #ccc;
  border-top: 0;
  background: white;
  box-shadow: 0px 5px 20px 5px rgba(50, 50, 50, 0.3);
  img {
    height: 5rem;
  }
}

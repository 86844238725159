.intro {
  position: relative;
  display: flex;
  height: 85vh;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;

  &:after {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(52, 32, 36, 0.6);
  }

  .container {
    text-align: center;
    flex-direction: column;
    align-items: center;
  }

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
    background: url(/assets/img/intro-background.jpg) no-repeat;
    background-size: cover;
  }

  &__news {
    padding: 0.5rem 0.5rem 0.5rem 0.12rem;
    background: rgba(slategrey, 0.6);
    z-indeX: 1;
    color: white;
    border-radius: 4px;
    margin-bottom: 2rem;

    span {
      margin-right: 0.25rem;
      padding: 0.25rem 0.5rem;
      background: rgba(lightseagreen, 0.8);
    border-radius: 4px;
    }
  }

  h1,
  p {
    color: white;
    text-shadow: rgba(0, 0, 0, 0.5) 1px 1px 10px;
    position: relative;
    z-index: 1;
  }

  h1 {
    font-weight: 500;
  }

  p {
    margin: 2em 0;
    position: relative;
    z-index: 1;
  }
}
